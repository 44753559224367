
import { defineComponent } from "vue";
import ToDoList from "@/components/ToDoList.vue";

export default defineComponent({
  name: "Home",
  components: {
    ToDoList,
  },
});
