
import { defineComponent } from "vue";
import BackToHome from "@/components/BackToHome.vue";

export default defineComponent({
  name: "NotFound",
  components: {
    BackToHome: BackToHome,
  },
});
