
import { defineComponent } from "vue";
import { store } from "@/store";
import { search } from "@/utils";

export default defineComponent({
  name: "ToDoList",
  data: () => {
    return {
      todos: store.state.todos,
      searchQuery: "",
    };
  },
  methods: {
    addTodo() {
      const id = store.state.todos.length
        ? Math.max(...store.state.todos.map((todo) => todo.id)) + 1
        : 0;
      this.$router.push({
        name: "todo",
        params: { id },
        query: { edit: "true" },
      });
    },
    onCheck() {
      store.saveTodos();
    },
  },
  computed: {
    searchTodos() {
      let todos;
      if (this.searchQuery && this.searchQuery.length > 3) {
        todos = search(this.todos, this.searchQuery);
      } else {
        todos = this.todos;
      }

      return todos.filter((todo) => !todo.completed);
    },
  },
});
